export default {
  useColorSchemeMediaQuery: true,
  initialColorMode: 'light',
  
  // TODO: Adjust colors.
  colors: {    
    text: '#040d14',
    background: 'hsl(10, 10%, 98%)',
    highlight: 'hsl(10, 40%, 90%)',
    gray: 'hsl(10, 20%, 50%)',

    modes: {
      times: {
        text: '#33302e',
        background: '#fff1e6',
        highlight: '#efeffe', // '#ffffcc',
        gray: '#777777',
        darken: 'rgba(0, 0, 0, .25)',
      },
      light: {
        text: '#040d14',
        background: 'hsl(10, 10%, 98%)',
        highlight: 'hsl(10, 40%, 90%)',
        gray: 'hsl(10, 20%, 50%)',
      },
      dark: {
        text: '#ffffff', 
        background: '#0A0A0A',
        highlight: '#29112c', // something bright 
      },
    },
  },

  fonts: {
    sansSerif: 'system-ui, Roboto, Helvetica, Arial, \'Segoe UI\', ui-sans-serif, sans-serif',
    serif: 'Georgia, ui-serif, serif',
    monospace: 'ui-monospace, monospace',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontWeights: {
    light: 300, 
    regular: 400, 
    bold: 700,
  },

  space: [0, 2, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512],

  styles: {
    root: {
      transitionProperty: 'background-color',
      transitionTimingFunction: 'ease-out',
      transitionDuration: '.25s',
    },
    h1: {
      fontSize: 48,
      lineHeight: 1.125,
      fontFamily: 'sansSerif',
      fontWeight: 'light',
      mt: 6, 
      mb: 3, 
    },
    h2: {
      fontSize: 32,
      lineHeight: 1.125,
      fontFamily: 'sansSerif',
      mt: 5, 
      mb: 3, 
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.125,
      fontFamily: 'sansSerif',
      mt: 4, 
      mb: 3, 
    },
    p: {
      fontSize: 18,
      fontFamily: 'serif',
      lineHeight: 1.6,
      mb: 5,
      maxWidth: 700,
    },
    img: {
      border: '1px solid',
      mb: 5,
    },
  },
};